<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-2>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Foto - {{ branch.comp_b_titulo }}</h2>
        </v-flex>
        <v-flex xs2 text-right mr-3>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'branchs'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-imagem-branch :branch="branch"></form-imagem-branch>
</span>
</template>

<script>
import FormImagemBranchComponent from "./partial/FormImagemBranchComponent";

export default {
    name: "AddFotoBranchComponent",
    created() {
        this.loadCompanyBranch()
    },
    props: {
        require: true,
        comp_b_id: {
            require: true
        }
    },
    data() {
        return {
            branch: {},
            btnLabel: 'Salvar',
        }
    },
    methods: {
        loadCompanyBranch() {
            this.$store.dispatch('loadCompanyBranch', this.comp_b_id)
                .then(response => this.branch = response)
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {
        formImagemBranch: FormImagemBranchComponent,
    }
};
</script>

<style scoped>

</style>
