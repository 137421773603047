<template>
<span>

    <v-form ref="form" lazy-validation autocomplete="off">
        <v-container fluid pl-0>

            <v-layout row justify-center>
                <v-flex xs2>
                    <input type="file" ref="file" style="display:none;" id="files" @change="onFileChange" />
                    <v-btn v-if="!preview && !branch.comp_b_imagem" color="blue-grey" class="btn-upload white--text" @click="$refs.file.click()">
                        Adicionar Imagem
                        <v-icon right dark>cloud_upload</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>

            <v-layout row justify-center>

                <v-flex v-if="preview || branch.comp_b_imagem">

                    <v-card>
                        <v-img v-if="preview" class="img-preview" :src="preview">
                        </v-img>

                        <v-img v-if="branch.comp_b_imagem && !preview" class="img-preview" :src="`${base_url}upload/company_branch/g/${branch.comp_b_imagem}`">
                        </v-img>

                        <v-card-actions class="justify-center">
                            <v-btn icon>
                                <v-icon @click="$refs.file.click()">cloud_upload</v-icon>
                            </v-btn>
                            <v-btn icon>
                                <v-icon @click.prevent="removePreview">highlight_off</v-icon>
                            </v-btn>
                        </v-card-actions>

                    </v-card>

                </v-flex>

            </v-layout>

        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'

export default {
    created() {

    },
    props: {
        branch: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    comp_b_id: '',
                    company_id: '',
                    comp_b_titulo: '',
                    comp_b_cnpj: '',
                    comp_b_ie: '',
                    comp_b_endereco: '',
                    comp_b_numero: '',
                    comp_b_bairro: '',
                    comp_b_cidade: '',
                    comp_b_estado: '',
                    comp_b_cep: '',
                    comp_b_complemento: '',
                    comp_b_caixapostal: '',
                    comp_b_email: '',
                    comp_b_prefixo: '',
                    comp_b_fone_1: '',
                    comp_b_fone_2: '',
                    comp_b_fone_3: '',
                    comp_b_celular: '',
                    comp_b_imagem: '',
                    comp_b_mapa: '',
                    comp_b_link_facebook: '',
                    comp_b_link_instagram: '',
                    comp_b_link_twitter: '',
                    comp_b_contato: '',
                    comp_b_status: '',
                }
            },
            require: true,
            comp_b_id: {
                require: true
            }
        },
    },
    name: "FormImagemBranchComponent",
    data() {
        return {
            upload: null,
            fileName: 'Selecione uma foto',
            preview: null,
            base_url: URL_BASE
        }
    },
    methods: {
        onSubmit() {

            const formData = new FormData();

            if (this.upload !== null)
                formData.append('comp_b_imagem', this.upload);

            formData.append('comp_b_id', this.branch.comp_b_id);

            this.$store.dispatch('updateImagemCompanyBranch', formData)
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: 'Foto atualizada com sucesso',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Algo deu errado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                    this.errors = error.response.data.errors
                })
        },
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files;

            if (!files.length)
                return;

            this.upload = files[0];
            this.fileName = this.upload.name;

            this.previewImage(files[0]);
        },
        previewImage(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.preview = e.target.result;
            };
            reader.readAsDataURL(file)

            this.onSubmit()
        },
        removePreview() {
            this.preview = null;
            this.branch.comp_b_imagem = '';
            this.upload = null;
            this.onSubmit()
        },
    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-upload,
.btn-salvar {
    margin: 0;
}

/*Also*/
.fileinput-button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    filter: 'alpha(opacity=0)';
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
}

.img-preview {
    max-width: 100%;
    height: 500px;
}
</style>
